// cfg

const mutations = {
	getDFields(state, objects) {
  		state.fields = objects;
	},
	getDTables(state, objects) {
		state.tables = objects;
  },

};

export default mutations;